import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import { Link } from "gatsby"
import Image from "../components/image"
import Code from '../components/code';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pricing from '../components/pricing'
import {MdCheckCircle, MdCancel} from 'react-icons/md';
import Tabs from '../components/tabs';
import Section from '../components/section';
import Button from '../components/button';
import {FadeIn} from '../animations/anim';



const TH = styled.th`
  padding: 0 4px 0 4px;
  min-width: 40px;
  color: white;
`;

const TD = styled.td`
  color: white;
  padding: 0 4px 0 4px;
`;

const Table = styled.table`
  font-size: 11px;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0px 16px;
`;

const ImageItem = styled.img`
  border-radius: 4px;
  padding: 8px;
  background-color: #C9DDFF14;
  animation-name: '${FadeIn}';
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-delay: ${props => `${props.animDelay}s`};
  opacity: 0;
  /*animation-play-state: ${props => props.animState};*/
`






const Row = styled.div`
  display: flex;
  flex-direction: row;
`;




const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Highlight = styled.b`
`



class DatahubPage extends Component {

  state = {
    codeAnimState: 'paused',
    tabSelected: null,
    pricingSectionVisible: true,
    weatherSecionVisible: false
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        codeAnimState: 'running'
      })
    }, 2500)
  }

  render() {
    return (
      <Layout {...this.props}>
        <SEO title="Datahub" />
        <Section style={{marginTop: 128}}>
          <h1 style={{fontSize: 64}}>
            <span style={{fontWeight: 300, fontSize: 32}}>The world's first</span>
            <br/>
            <span style={{color: '#C9DDFF', fontWeight: 900}}>AI-READY</span><span style={{fontWeight: 700}}> DATAHUB</span>
          </h1>
          <Row style={{marginTop: 32, flexWrap: 'wrap'}}>
            <Col style={{flex: 2, marginRight: 32, minWidth: 400}}>
              <h2 style={{fontSize: 36, fontWeight: 400}}>
                For developers,
                <span style={{color: '#C9DDFF'}}> by developers</span>
              </h2>
              <p>
                Our datahub is built with developers and data scientists in mind. It's easy to use and integrates seamlessly with popular data science tools like pandas.
              </p>
              <Button
                text='Read the docs'
                to='https://api.rebase.energy/weather/docs/v2'
                style={{marginBottom: 16, maxWidth: 200}}/>
            </Col>
            <Col style={{flex: 3, overflow: 'hidden', minWidth: 400}}>
              <Code
                animState={this.state.codeAnimState}
                codeRows={[
                  {code: 'import requests'},
                  {code: 'import pandas as pd'},
                  {code: '', extraDuration: 0.6},
                  {code: 'params = {'},
                  {code: '\t\'model\': "NCEP_GFS",'},
                  {code: '\t\'variables\': ["WindUMS_Height", "WindVMS_Height"],', extraDuration: -0.7},
                  {code: '\t\'dimensions\': {\'latitude\': [51.3], \'longitude\': [9.2]},', extraDuration: -0.7},
                  {code: '\t\'start_date\': "2019-06-17 00", \'end_date\': "2020-07-21 00"', extraDuration: -0.7},
                  {code: '}', extraDuration: 0.6},
                  {code: '', extraDuration: 0.2},
                  {code: 'res = requests.get("https://api.rebase.energy/v2/nwp", params=params)', extraDuration: -0.4},
                  {code: 'df = pd.read_json(res.text)'},
                  {code: 'print(df)'},
                ]}
                outRows={[
                  {out: <Table>
                    <tr>
                      <TH></TH>
                      <TH></TH>
                      <TH>WindUMS_Height</TH>
                      <TH>WindVMS_Height</TH>
                    </tr>
                    <tr>
                      <TD>ref_datetime</TD>
                      <TD>valid_datetime</TD>
                      <TD></TD>
                      <TD></TD>
                    </tr>
                    <tr>
                      <TD>2019-06-17 00:00</TD>
                      <TD>2019-06-17 00:00</TD>
                      <TD>1.587919</TD>
                      <TD>-2.911504</TD>
                    </tr>
                    <tr>
                      <TD></TD>
                      <TD>2020-07-28 01:00</TD>
                      <TD>1.925079</TD>
                      <TD>-2.281904</TD>
                    </tr>
                    <tr>
                      <TD>...</TD>
                      <TD>...</TD>
                      <TD>...</TD>
                      <TD>...</TD>
                    </tr>
                    <tr>
                      <TD>2020-07-21 00:00</TD>
                      <TD>2020-08-05 21:00</TD>
                      <TD>-1.042598</TD>
                      <TD>-1.307412</TD>
                    </tr>
                    <tr>
                      <TD></TD>
                      <TD>2020-08-06 00:00</TD>
                      <TD>-0.622930</TD>
                      <TD>0.042861</TD>
                    </tr>
                  </Table>}
                  //{out: '\tWindUMS_Height   WindVMS_Height'},
                  //{out: 'ref_datetime,    valid_datetime'},
                  //{out: '2019-06-17 00:00,    2019-06-17 00:00'}
                ]}
              />
            </Col>
          </Row>
        </Section>
        <Col style={{marginTop: 256,  alignItems: 'center'}}>
          <p1 style={{fontSize: 28}}>
            What makes our datahub AI-ready?
          </p1>
          <hr style={{width: 2, marginTop: 32, height: 200, backgroundColor: '#C9DDFF'}}/>
        </Col>

        <Section
          style={{marginTop: 32}}
          onVisible={() => {
            this.setState({
              weatherSecionVisible: true,
            })
          }}
          >
          <h1 style={{fontSize: 48}}>
            Historical weather forecasts
          </h1>
          <h3 style={{color: '#C9DDFF'}}>
            Learn from the past
          </h3>
          <Row>
            <Col style={{flex: 1, minWidth: 400}}>
              <p>
                Compared to most other weather APIs,
                we provide historical weather forecasts which are essential
                for training machine learning algorithms.
              </p>
              <ImageGrid>
                {[
                  require('../images/weather/ecmwf.png'),
                  require('../images/weather/gfs.png'),
                  require('../images/weather/esa.png'),
                  require('../images/weather/fmi.png'),
                  require('../images/weather/nmi.png'),
                  require('../images/weather/dwd.png'),
                  require('../images/weather/meteofrance3.png'),
                  require('../images/weather/metoffice3.png')
                ].map((url, i) => <ImageItem
                  animState={'running'}
                  key={`img_${i}`}
                  animDelay={0.2*i}
                  src={url}/>)}
              </ImageGrid>
            </Col>

            <div style={{flex: 1}}/>
          </Row>

        </Section>
        <Section>
          <h1 style={{textAlign: 'center', fontSize: 48}}>
            Pricing for <span style={{color: '#C9DDFF'}}>every need.</span>
          </h1>
          <Row style={{justifyContent: 'center', marginTop: 32}}>
            <p>
              Select pricing below
            </p>
          </Row>
          <Row style={{justifyContent: 'center'}}>
            <Tabs
              style={{marginTop: 16}}
              selected={this.state.tabSelected}
              animIterationCount={this.state.tabSelected === null ? 'infinite' : 0}
              onChange={(t) => this.setState({tabSelected: t.key})}
              tabStyle={{backgroundColor: '#181818', fontSize: 18}}
              tabs={[
                {key: 'singleModel', name: 'Single model'},
                {key: 'multiModel', name: 'Multiple models'},
                {key: 'reanalysis', name: 'Reanalysis data'},
                {key: 'historical', name: 'Historical forecasts'}
              ]}
            />
          </Row>
          <div style={{height: 2, width: '100%', backgroundColor: '#C9DDFF22', marginTop: 8}}/>
          {this.state.tabSelected === 'singleModel' &&
          <>
          <h2 style={{marginTop: 32, color: '#C9DDFF'}}>
            Single model
          </h2>
          <Row>
            <p style={{flex: 2}}>
              Our single model plans give you access to our unified weather model, which mixes our open weather models and returns the best data at your given location.
              It provides a standard set of variables that are easy to get started with.
            </p>
            <div style={{flex: 1}}/>
          </Row>

          <Row style={{alignItems: 'center'}}>
            <MdCheckCircle style={{color: '#C9DDFF'}}/><span style={{marginLeft: 8}}>Included</span>
          </Row>
          <Row style={{alignItems: 'center'}}>
            <MdCancel style={{color: '#ffc9c9'}}/><span style={{marginLeft: 8}}>Not included</span>
          </Row>
          <Pricing
            animState={this.state.pricingSectionVisible ? 'running' : 'paused'}
            style={{marginTop: 16}}
            prices={[
              {
                title: 'Free',
                price: '€0/month',
                priceExtra: 'Free forever. No credit card needed.',
                features: [
                  {component: <Highlight>500 requests/day</Highlight>},
                  {component: 'Global coverage'},
                  {component: 'Standard variables'},
                  {component: 'Hourly forecast / 2 days'},
                  {component: 'Commercial license', included: false}
                ],
                button: {
                  text: 'Start now',
                  to: 'https://dashboard.rebase.energy/auth/signup/single/free'
                }
              },
              {
                title: 'Basic',
                price: '€50/month',
                priceExtra: 'Billed anually. €60 billed monthly.',
                features: [
                  {component: <Highlight>50 000 requests/day</Highlight>},
                  {component: 'Global coverage'},
                  {component: 'Standard variables'},
                  {component: 'Hourly forecast / 2 days'},
                  {component: <Highlight>Commercial license</Highlight>},
                ],
                button: {
                  text: 'Start now',
                  to: 'https://dashboard.rebase.energy/auth/signup/single/basic'
                }
              },
              {
                title: 'Pro',
                price: '€150/month',
                priceExtra: 'Billed anually. €180 billed monthly.',
                features: [
                  {component: <Highlight>500 000 requests/day</Highlight>},
                  {component: 'Global coverage'},
                  {component: 'Standard variables'},
                  {component: <Highlight>Hourly forecast / 5 days</Highlight>},
                  {component: 'Commercial license'},
                ],
                button: {
                  text: 'Start now',
                  to: 'https://dashboard.rebase.energy/auth/signup/single/pro'
                }              },
              {
                title: 'Premium',
                price: '€350/month',
                priceExtra: 'Billed anually. €420 billed monthly.',
                features: [
                  {component: <Highlight>5 000 000 requests/day</Highlight>},
                  {component: 'Global coverage'},
                  {component: 'Standard variables'},
                  {component: <Highlight>Hourly forecast / 10 days</Highlight>},
                  {component: 'Commercial license'},
                ],
                button: {
                  text: 'Start now',
                  to: 'https://dashboard.rebase.energy/auth/signup/single/premium'
                }
              },
              {
                title: 'Enterprise',
                price: 'From €600/month',
                priceExtra: 'Billed anually.',
                features: [
                  {component: <Highlight>+5 000 000 requests/day</Highlight>},
                  {component: 'Global coverage'},
                  {component: 'Standard variables'},
                  {component: 'Hourly forecast / 10 days'},
                  {component: <Highlight>SLA</Highlight>},
                  {component: <Highlight>Custom weather file bulk delivery</Highlight>},
                  {component: 'Commercial license'},
                ],
                button: {
                  text: 'Contact sales',
                  to: '/contact'
                }
              },
            ]}
          />
        </>
        }
        {this.state.tabSelected === 'multiModel' &&
        <>
        <h2 style={{marginTop: 32, color: '#C9DDFF'}}>
          Multiple models
        </h2>
        <Row>
          <p style={{flex: 2}}>
            Our multi-model plans give you access to all of our open weather models. You have full control of the models you query, as well as their original variables.
          </p>
          <div style={{flex: 1}}/>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <MdCheckCircle style={{color: '#C9DDFF'}}/><span style={{marginLeft: 8}}>Included</span>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <MdCancel style={{color: '#ffc9c9'}}/><span style={{marginLeft: 8}}>Not included</span>
        </Row>
        <Pricing
          animState={this.state.pricingSectionVisible ? 'running' : 'paused'}
          style={{marginTop: 16}}
          prices={[
            {
              title: 'Free',
              price: '€0/month',
              priceExtra: 'Free forever. No credit card needed.',
              features: [
                {component: <Highlight>50 requests/day</Highlight>},
                {component: 'Global coverage'},
                {component: 'All open weather models'},
                {component: 'All variables'},
                {component: 'Hourly forecast / 2 days'},
                {component: 'Commercial license', included: false}
              ],
              button: {
                text: 'Start now',
                to: 'https://dashboard.rebase.energy/auth/signup/multi/free'
              }
            },
            {
              title: 'Basic',
              price: '€50/month',
              priceExtra: 'Billed anually. €60 billed monthly.',
              features: [
                {component: <Highlight>1 000 requests/day</Highlight>},
                {component: 'Global coverage'},
                {component: 'All open weather models'},
                {component: 'All variables'},
                {component: 'Hourly forecast / 2 days'},
                {component: <Highlight>Commercial license</Highlight>},
              ],
              button: {
                text: 'Start now',
                to: 'https://dashboard.rebase.energy/auth/signup/multi/basic'
              }
            },
            {
              title: 'Pro',
              price: '€150/month',
              priceExtra: 'Billed anually. €180 billed monthly.',
              features: [
                {component: <Highlight>5 000 requests/day</Highlight>},
                {component: 'Global coverage'},
                {component: 'All open weather models'},
                {component: 'All variables'},
                {component: <Highlight>Hourly forecast / 5 days</Highlight>},
                {component: 'Commercial license'},
              ],
              button: {
                text: 'Start now',
                to: 'https://dashboard.rebase.energy/auth/signup/multi/pro'
              }              },
            {
              title: 'Premium',
              price: '€350/month',
              priceExtra: 'Billed anually. €420 billed monthly.',
              features: [
                {component: <Highlight>25 000 requests/day</Highlight>},
                {component: 'Global coverage'},
                {component: 'All open weather models'},
                {component: 'All variables'},
                {component: <Highlight>Hourly forecast / 10 days</Highlight>},
                {component: 'Commercial license'},
              ],
              button: {
                text: 'Start now',
                to: 'https://dashboard.rebase.energy/auth/signup/multi/premium'
              }
            },
            {
              title: 'Enterprise',
              price: 'From €600/month',
              priceExtra: 'Billed anually.',
              features: [
                {component: <Highlight>+25 000 requests/day</Highlight>},
                {component: 'Global coverage'},
                {component: 'All open weather models'},
                {component: 'All variables'},
                {component: 'Hourly forecast / 10 days'},
                {component: <Highlight>Historical data</Highlight>},
                {component: <Highlight>SLA</Highlight>},
                {component: <Highlight>Custom weather file bulk delivery</Highlight>},
                {component: 'Commercial license'},
              ],
              button: {
                text: 'Contact sales',
                to: '/contact'
              }
            },
          ]}
        />
      </>
      }
      {this.state.tabSelected === 'reanalysis' &&
        <>
        <h2 style={{marginTop: 32, color: '#C9DDFF'}}>
          Reanalysis data
        </h2>
        <Row>
          <p style={{flex: 2}}>
            Our reanalysis packages give you access to data-months which can be used to query over 40 years of reanalysis data. Reanalysis combines model data with observations from across the world into a globally complete and consistent dataset.
          </p>
          <div style={{flex: 1}}/>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <MdCheckCircle style={{color: '#C9DDFF'}}/><span style={{marginLeft: 8}}>Included</span>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <MdCancel style={{color: '#ffc9c9'}}/><span style={{marginLeft: 8}}>Not included</span>
        </Row>
        <Pricing
          animState={this.state.pricingSectionVisible ? 'running' : 'paused'}
          style={{marginTop: 16}}
          prices={[
            {
              title: 'Small',
              price: '€20 (€0.2/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>100 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'ERA5 and MESAN data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Medium',
              price: '€150 (€0.15/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>1 000 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'ERA5 and MESAN data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Large',
              price: '€400 (€0.12/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>3 000 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'ERA5 and MESAN data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Extra Large',
              price: '€800 (€0.08/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>10 000 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'ERA5 and MESAN data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Enterprise',
              price: 'From €1000',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>+10 000 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'ERA5 and MESAN data'},
                {component: <Highlight>Custom weather file bulk delivery</Highlight>},
                {component: 'Commercial license'},
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
          ]}
        />
      </>
      }
      {this.state.tabSelected === 'historical' &&
        <>
        <h2 style={{marginTop: 32, color: '#C9DDFF'}}>
          Historical forecasts
        </h2>
        <Row>
          <p style={{flex: 2}}>
            Our historical forecast packages give you access to data-months which can be used to query all the historical forecast data of our open weather models.
            Historical forecasts can often be the most valuable for machine learning purposes.
          </p>
          <div style={{flex: 1}}/>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <MdCheckCircle style={{color: '#C9DDFF'}}/><span style={{marginLeft: 8}}>Included</span>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <MdCancel style={{color: '#ffc9c9'}}/><span style={{marginLeft: 8}}>Not included</span>
        </Row>
        <Pricing
          animState={this.state.pricingSectionVisible ? 'running' : 'paused'}
          style={{marginTop: 16}}
          prices={[
            {
              title: 'Small',
              price: '€50 (€2.5/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>20 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All variables'},
                {component: 'All models'},
                {component: 'Hourly data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Medium',
              price: '€300 (€2/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>150 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All models'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Large',
              price: '€875 (€1.75/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>500 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All models'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Extra Large',
              price: '€1500 (€1.5/data-month)',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>1500 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All models'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: 'Commercial license'}
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
            {
              title: 'Enterprise',
              price: 'From €2000',
              priceExtra: 'One time payment. Use credits when needed.',
              features: [
                {component: <Highlight>+1 500 data-months</Highlight>},
                {component: 'Global coverage'},
                {component: 'All models'},
                {component: 'All variables'},
                {component: 'Hourly data'},
                {component: <Highlight>Custom weather file bulk delivery</Highlight>},
                {component: 'Commercial license'},
              ],
              button: {
                text: 'Buy now',
                to: 'https://dashboard.rebase.energy/auth/signup/single/free'
              }
            },
          ]}
        />
      </>
      }
        </Section>
        <Row style={{width: '100%', backgroundColor: '#c9ddff11', paddingTop: 24, paddingBottom: 24, marginTop: 128}}>
          <Col style={{marginLeft: 48}}>
            <h2>
              Learn more
            </h2>
            <Button text='Read the docs' to='https://api.rebase.energy/weather/docs/v2'/>
          </Col>
        </Row>
      </Layout>
    );
  }

}

export default DatahubPage;
