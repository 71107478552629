import React from 'react';
import styled, {keyframes} from 'styled-components';

export const FadeIn = keyframes`
  0% {
    transform: scale(1);
  };
  10% {
    transform: scale(1.04);
    background-color: #C9DDFF;
    color: #424242;
  };
  20% {
    transform: scale(1);
    background-color: #C9DDFF22;
    color: white;
  };
  100% {
    transform: scale(1);
  };
`;

const Tab = styled.div`
  animation-name: '${FadeIn}';
  animation-duration: 5s;
  animation-iteration-count: ${props => props.animIterationCount};
  animation-delay: ${props => `${props.animDelay}s`};
  animation-play-state: ${props => props.animState};
  position: relative;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  color: ${props => props.selected ? '#424242' : 'inherit'};
  cursor: pointer;
  user-select: none;
  background-color: ${props => props.selected ? '#C9DDFF' : '#C9DDFF22'};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &:hover {
    color: ${props => props.selected ? '#424242' : '#C9DDFF'};
    background-color: ${props => props.selected ? '#C9DDFF' : '#C9DDFF44'};
    transform: ${props => props.selected ? 'scale(1)' : 'scale(1.04)'};
  }
  &::after {
    position: absolute;
    content: '';
    bottom: -1.5px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    /*opacity: ${props => props.selected ? 1 : 0};*/
    opacity: 0;
    background-color: #C9DDFF;
    transition-duration: 0.4s;
  }
  transition-duration: 0.4s;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Tabs = ({tabs, selected, tabStyle, onChange, style, animState, animIterationCount}) => (
  <Row style={{...style}}>
    {tabs.map((t, i) => {
      return (
        <Tab
          key={`${t.key}`}
          tabStyle={tabStyle}
          style={{marginRight: 16}}
          selected={selected === t.key}
          onClick={() => onChange(t)}
          animDelay={0.3*i+2}
          animState={animState}
          animIterationCount={animIterationCount}
          >
          {t.name}
        </Tab>
      )
    })}
  </Row>
);

export default Tabs;
